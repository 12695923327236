import React, { useState, useEffect } from "react";
import { BikeCompareStorage } from "./BikeCompareStorage";
import { makeStyles } from "@material-ui/core/styles";
import ButtonLink from "../Navigation/ButtonLink";
import useTranslation from "next-translate/useTranslation";

const animationTimeMs = 1000;

const useStyles = makeStyles((theme) => ({
  text: {
    paddingRight: theme.spacing(1),
    fontWeight: 300,
  },
  icon: {
    backgroundColor: theme.bikeCompareColor,
    color: theme.palette.primary.main,
    textAlign: "center",
    width: theme.bikeCompareIconSize,
    height: theme.bikeCompareIconSize,
    borderRadius: theme.bikeCompareIconSize,
    fontSize: theme.bikeCompareIconSize * 0.7,
    fontWeight: 900,
    lineHeight: `${theme.bikeCompareIconSize}px`,
    cursor: "pointer",
    display: "inline-block",
  },
  animate: {
    "&:after": {
      content: "''",
      display: "block",
      width: theme.bikeCompareIconSize + 7,
      height: theme.bikeCompareIconSize + 7,
      borderRadius: "50%",
      border: `5px solid ${theme.bikeCompareColor}`,
      position: "absolute",
      top: 7,
      right: 5,
      animation: `$pulse ${animationTimeMs}ms 0s infinite`,
    },
  },
  "@keyframes pulse": {
    "10%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "80%": {
      opacity: 0.5,
      transform: "scale(2)",
    },
    "100%": {
      opacity: 0,
      transform: "scale(3)",
    },
  },
}));

const Button = (props) => {
  const { className, flavor } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");
  const [count, setCount] = useState(BikeCompareStorage.getBikes(flavor).length);
  const [path, setPath] = useState(BikeCompareStorage.getBikes(flavor).join("_vs_"));
  const [animate, setAnimate] = useState(false);
  const [timeout, setTimeout] = useState();

  useEffect(() => {
    const deregister = BikeCompareStorage.onChange(flavor, (bikes) => {
      setCount(bikes.length);
      setPath(bikes.join("_vs_"));
      setAnimate(true);
      if (timeout) {
        clearTimeout(timeout);
        setTimeout(null);
      }
      setTimeout(() => {
        setTimeout(null);
        setAnimate(false);
      }, animationTimeMs);
    });
    return function cleanup() {
      deregister();
    };
  }, [flavor, timeout, setTimeout]);

  const compare_path = flavor == "ebike" ? "ebike-vergleich" : "fahrrad-vergleich";

  return (
    <ButtonLink
      href={path.length > 0 ? `/${compare_path}/[generic]` : `/${compare_path}`}
      as={path.length > 0 ? `/${compare_path}/${path}` : `/${compare_path}`}
      buttonClassName={className ? className : ""}
    >
      <span className={classes.text}>{t(`bikeComparisonTeaser_${flavor}`)}</span>
      {count > 0 && <span className={`${classes.icon} ${animate ? classes.animate : ""}`}>{count}</span>}
    </ButtonLink>
  );
};

export default Button;
