import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";
import { queryProducts } from "../../../graphql/queries";
import useSWR from "swr";
import ExternalLinkIcon from "../../Navigation/Icons/ExternalLinkIcon";
import OpenIcon from "../../Navigation/Icons/OpenIcon";
import Button from "@material-ui/core/Button";
import environment from "../../../environment";
import PriceTag from "./PriceTag";
import ProductHref from "./ProductHref";
import { TheCycleverseTheme } from "src/theme";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  openButton: {
    backgroundColor: theme.hightlightColorTertiary,
    color: theme.palette.primary.main,
    padding: "10px 50px",
    fontSize: "large",
    "& .MuiButton-endIcon": {
      marginLeft: 12,
    },
  },
  openIcon: {
    filter: "invert(1)",
    fontSize: 12,
  },
  price: {
    marginBottom: theme.spacing(2),
  },
}));

const ProductBestPriceComponent = (props) => {
  const { displayedProduct, className, showPrice } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <div className={className}>
      {showPrice && <PriceTag price={displayedProduct.price} className={classes.price} />}
      <ProductHref product={displayedProduct}>
        <Button component="div" className={classes.openButton} endIcon={<ExternalLinkIcon />}>
          {t("productInfoLink")}
        </Button>
      </ProductHref>
    </div>
  );
};

const ProductBestPrice = (props) => {
  const { product, className, showPrice } = props;
  let displayedProduct = product;

  if (!product.ean) {
    return (
      <ProductBestPriceComponent displayedProduct={displayedProduct} className={className} showPrice={showPrice} />
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useSWR([
    queryProducts,
    JSON.stringify({
      action: "productOffers",
      ean: product.ean,
      seoId: product.seoId,
    }),
  ]);

  const itemCount = data?.queryProducts?.items?.length;

  if (itemCount > 1) {
    data.queryProducts.items.sort((a, b) => (a.price.amount > b.price.amount ? 1 : -1));
    displayedProduct = data.queryProducts.items[0];
  }

  return <ProductBestPriceComponent displayedProduct={displayedProduct} className={className} showPrice={showPrice} />;
};

export default ProductBestPrice;
