import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTranslation from "next-translate/useTranslation";
import React, { FC } from "react";
import { BikeType } from "types";
import { Icon } from "@material-ui/core";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";

import ButtonLink from "src/components/Navigation/ButtonLink";
import CopyUrlButton from "src/components/Social/CopyUrlButton";
import ProductBestPrice from "src/components/Content/Product/ProductBestPrice";
import ProductCard from "src/components/Content/Product/ProductCard";

const tableHeaderWidth = 180;
const tableCellWidth = 200;

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  bikeDetailsTable: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      maxWidth: "100%",
    },
  },
  table: {
    maxWidth: "100%",
  },
  tableRow: {},
  tableHeaderCell: {
    wordBreak: "break-word",
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.globalMarginMobile,
    fontSize: 14,
    fontWeight: 600,
    borderRight: `1px solid ${theme.tableBorderColor}`,
    borderBottom: `1px solid ${theme.tableBorderColor}`,
  },
  tableHeaderCellTransparent: {
    backgroundColor: "inherit",
  },
  tableHeaderCellNoBorder: {
    border: "none",
  },
  tableHeaderCellNoPadding: {
    padding: 0,
  },
  stickyLeft: {
    position: "sticky",
    left: 0,
    zIndex: 1,
  },
  stickyTop: {
    position: "sticky",
    top: 0,
  },
  tableHeaderCellContent: {
    display: "inline-block",
    position: "sticky",
    left: theme.globalMarginMobile,
  },
  tableHeaderNamesWidth: {
    width: 150,
  },
  imageHeaderCell: {
    padding: "0px !important",
    minHeight: 100,
    border: "none !important",
  },
  tableHeaderCellBikeHack: {
    zIndex: 99,
    minWidth: tableHeaderWidth,
    maxWidth: tableHeaderWidth,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      display: "none",
    },
  },
  tableHeaderCellGroup: {
    fontSize: "medium",
    color: theme.productDetailGroupHeader,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.globalMarginMobile,
    fontWeight: 600,
    borderBottom: `1px solid ${theme.tableBorderColor}`,
  },
  tableHeaderCellGroupSpacer: {
    backgroundColor: theme.palette.background.default,
    padding: 0,
    paddingTop: 40,
  },
  tableHeaderCellGroupCell: {
    fontSize: "medium",
  },
  tableHeaderCellWhite: {
    backgroundColor: theme.palette.primary.main,
  },
  tableCell: {
    fontSize: "medium",
    minWidth: tableCellWidth,
    maxWidth: tableCellWidth,
    paddingLeft: theme.globalMarginMobile,
    paddingRight: theme.globalMarginMobile,
    border: `1px solid ${theme.tableBorderColor}`,
  },
  tableCellAlternateBackground: {
    backgroundColor: theme.palette.background.tertiary,
  },
  cardContainer: {
    padding: "0px 1px",
  },
  headline: {
    ...theme.headline2,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginLeft: tableHeaderWidth,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.globalMarginMobile,
      marginRight: theme.globalMarginMobile,
    },
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.globalMarginMobile,
      marginRight: theme.globalMarginMobile,
    },
  },
  buttonMenu: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.globalMarginMobile,
      marginRight: theme.globalMarginMobile,
    },
  },
  colorBar: {
    height: 5,
  },
  colorBarSmall: {
    height: 1,
  },
  brand: {
    color: theme.hightlightColor,
    fontSize: 18,
  },
  title: {
    fontSize: 18,
  },
  price: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "& .FX-PriceTag-price": {
      fontSize: 22,
    },
    "& .MuiButtonBase-root": {
      padding: "5px 15px",
      fontSize: 14,
    },
  },
  textAlignTop: {
    verticalAlign: "top",
  },
  addIcon: {
    backgroundImage: "url(/images/Bike-add.svg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "block",
  },
  addButton: {
    fontWeight: 300,
  },
}));

function createData(name, data) {
  return { name, data };
}

const getBarColor = (i) => {
  switch (i % 8) {
    case 0:
      return "#66BD6D";
    case 1:
      return "#F033A2";
    case 7:
      return "#7FFF7F";
    case 3:
      return "#00A99D";
    case 2:
      return "#33AAE1";
    case 5:
      return "#F68D91";
    case 6:
      return "#D47FCD";
    case 4:
      return "#FBB748";
    default:
      return "#fff";
  }
};

const SpecificationTable: FC<{
  className?: string;
  products: any;
  singleProduct?: boolean;
  flavor: BikeType | "product";
}> = ({ className, products, singleProduct = false, flavor }) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const isMobileLandscape = useMediaQuery(
    (theme: TheCycleverseTheme) => `${theme.breakpoints.down("sm")} and (orientation: landscape)`
  );
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm")) && !isMobileLandscape;

  const createRow = (name: string, data: any, className?: string) => {
    if (isDesktopView) {
      return (
        <TableRow key={name} className={classes.tableRow}>
          <TableCell component="th" scope="row" className={`${classes.stickyLeft} ${classes.tableHeaderCell} `}>
            {name}
          </TableCell>
          {data.map((d, i) => (
            <TableCell
              key={name + i}
              className={`${className ? className : ""} ${classes.tableCell} ${
                i % 2 == 1 ? classes.tableCellAlternateBackground : ""
              }`}
            >
              {d}
            </TableCell>
          ))}
        </TableRow>
      );
    } else {
      return [
        <TableRow key={name + " group"} className={classes.tableRow}>
          <TableCell component="th" scope="row" colSpan={products.length} className={`${classes.tableHeaderCell}`}>
            <div className={`${classes.tableHeaderCellContent}`}>{name}</div>
          </TableCell>
        </TableRow>,
        <TableRow key={name} className={classes.tableRow}>
          {data.map((d, i) => (
            <TableCell
              key={name + i}
              className={`${className ? className : ""} ${classes.tableCell} ${
                i % 2 == 1 ? classes.tableCellAlternateBackground : ""
              }`}
            >
              {d}
            </TableCell>
          ))}
        </TableRow>,
      ];
    }
  };

  const createRowWithData = (name, key, subKey, suffix, fieldType, floatOffset) => {
    const row = createData(
      name,
      products.map((bike) => {
        if (!bike.hasOwnProperty(key) || !bike[key]) {
          return null;
        }
        if (subKey && !bike[key].hasOwnProperty(subKey)) {
          return null;
        }
        var value = subKey ? bike[key][subKey] : bike[key];
        if (!value) {
          return null;
        }
        if (fieldType == "float" && floatOffset) {
          value = (parseFloat(value) / floatOffset).toFixed(1);
        }
        if (suffix) {
          return `${value}${suffix}`;
        } else {
          return `${value}`;
        }
      })
    );
    if (row.data.filter((d) => d != null).length <= 0) {
      return null;
    }
    return createRow(row.name, row.data);
  };

  const createRowWithDetailData = (key: string, suffix?: string, fieldType?: string, floatOffset?: number) => {
    return createRowWithData(t(`bikeDetails_${key}`), "bikeDetail", key, suffix, fieldType, floatOffset);
  };

  const createRowGroup = (name, rows) => {
    if (!rows || rows.filter((e) => e).length <= 0) {
      return null;
    }
    return [
      <TableRow key={name + "groupEmpty"} className={classes.tableRow}>
        {isDesktopView && (
          <TableCell
            component="th"
            scope="row"
            className={`${classes.stickyLeft} ${classes.tableHeaderCellGroupSpacer}`}
          />
        )}
        {products.map((b, i) => (
          <TableCell key={name + "groupEmpty" + i} className={classes.tableHeaderCellGroupSpacer}>
            {!singleProduct && <div className={classes.colorBarSmall} style={{ backgroundColor: getBarColor(i) }} />}
          </TableCell>
        ))}
      </TableRow>,
      <TableRow key={name + " group"} className={classes.tableRow}>
        <TableCell
          component="th"
          scope="row"
          colSpan={isDesktopView ? 1 : products.length}
          className={`${isDesktopView ? classes.stickyLeft : ""} ${classes.tableHeaderCellGroup}`}
        >
          <div className={isDesktopView ? "" : classes.tableHeaderCellContent}>{name}</div>
        </TableCell>
        {isDesktopView &&
          products.map((b, i) => (
            <TableCell key={name + i} className={`${classes.tableHeaderCellGroupCell} ${classes.tableHeaderCell}`} />
          ))}
      </TableRow>,
      ...rows,
    ];
  };

  return (
    <div className={`${className ? className : ""} ${classes.bikeDetailsTable}`}>
      {!singleProduct && <h2 className={classes.headline}>{t(`bikeComparisonHeadline_${flavor}`)}</h2>}
      {!singleProduct && (
        <div className={classes.buttonMenu}>
          <CopyUrlButton />
          {flavor == "ebike" && (
            <ButtonLink
              href="/[...generic]"
              as="/e-bikes"
              endIcon={<Icon className={classes.addIcon} />}
              buttonClassName={classes.addButton}
            >
              {t("bikeComparisonAddEbike")}
            </ButtonLink>
          )}
          {flavor == "biobike" && (
            <ButtonLink
              href="/[...generic]"
              as="/fahrraeder"
              endIcon={<Icon className={classes.addIcon} />}
              buttonClassName={classes.addButton}
            >
              {t("bikeComparisonAdd")}
            </ButtonLink>
          )}
        </div>
      )}

      <TableContainer>
        <Table className={`${classes.table}`} aria-label="simple table">
          {!singleProduct && (
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell
                  className={`${classes.tableHeaderCellBikeHack} ${classes.stickyLeft} ${classes.tableHeaderCell} ${classes.tableHeaderCellTransparent} ${classes.tableHeaderCellNoBorder}`}
                />
                {products.map((bike, i) => (
                  <TableCell key={bike.seoId} className={`${classes.tableCell} ${classes.imageHeaderCell}`}>
                    <div className={classes.cardContainer}>
                      <ProductCard
                        product={bike}
                        hideDetails={true}
                        forceShowBikeCompare={true}
                        bikeCompareFlavor={flavor}
                      />
                    </div>
                    <div className={classes.colorBar} style={{ backgroundColor: getBarColor(i) }} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {flavor == "product" && products[0].specificationGroups && (
              <>
                {products[0].specificationGroups.map((group) => {
                  return createRowGroup(
                    group.key,
                    group.value.map((value) => createRow(value.key, [value.value]))
                  );
                })}
              </>
            )}

            {(flavor == "ebike" || flavor == "biobike") && (
              <>
                {createRow(
                  t("productDetailManufacturer"),
                  products.map((bike) => (
                    <div key={bike.id}>
                      <div className={classes.brand}>{bike.brand}</div>
                      <div className={classes.title}>{bike.title}</div>
                    </div>
                  )),
                  classes.textAlignTop
                )}
                {!singleProduct &&
                  createRow(
                    t("productDetailPrice"),
                    products.map((bike) => (
                      <ProductBestPrice key={bike.id} showPrice={true} product={bike} className={classes.price} />
                    ))
                  )}
                {createRow(
                  t("productDetailCategory"),
                  products.map((bike) => bike.category.split(" > ").pop())
                )}
                {createRowWithDetailData("weight")}

                {createRowGroup("Rahmen & Form", [
                  createRowWithDetailData("frame"),
                  createRowWithDetailData("frameMaterial"),
                  createRowWithDetailData("frameType"),
                ])}

                {createRowGroup("Geometrie", [
                  createRowWithDetailData("stackReachRatio", "", "float", 100),
                  createRowWithDetailData("stackMM", " mm"),
                  createRowWithDetailData("reachMM", " mm"),
                  createRowWithDetailData("wheelbaseMM", " mm"),
                  createRowWithDetailData("frontCenterMM", " mm"),
                  createRowWithDetailData("rakeMM", " mm"),
                  createRowWithDetailData("trailMM", " mm"),
                  createRowWithDetailData("topTubeLengthMM", " mm"),
                  createRowWithDetailData("seatTubeAngle", "°", "float", 100),
                  createRowWithDetailData("seatTubeLengthMM", " mm"),
                  createRowWithDetailData("headTubeAngle", "°", "float", 100),
                  createRowWithDetailData("headTubeLengthMM", " mm"),
                  createRowWithDetailData("chainstayLengthMM", " mm"),
                  createRowWithDetailData("bottomBracketDropMM", " mm"),
                  createRowWithDetailData("bottomBracketHeightMM", " mm"),
                ])}

                {flavor == "ebike" &&
                  createRowGroup("E-Bike Spezifikation", [
                    createRowWithDetailData("assistanceLevel"),
                    createRowWithDetailData("batteryCapacity"),
                    createRowWithDetailData("batteryPack"),
                    createRowWithDetailData("batteryPosition"),
                    createRowWithDetailData("charger"),
                    createRowWithDetailData("dynamo"),
                    createRowWithDetailData("displayType"),
                    createRowWithDetailData("engine"),
                    createRowWithDetailData("engineManufacturer"),
                    createRowWithDetailData("enginePosition"),
                    createRowWithDetailData("motorTorque"),
                    createRowWithDetailData("batteryLoadingTime"),
                    createRowWithDetailData("batteryIsRemovable"),
                    createRowWithDetailData("speed"),
                    createRowWithDetailData("tractionHelp"),
                  ])}

                {createRowGroup("Steuerung & Federung", [
                  createRowWithDetailData("fork"),
                  createRowWithDetailData("forkMaterial"),
                  createRowWithDetailData("headset"),
                  createRowWithDetailData("spacer"),
                ])}

                {createRowGroup("Cockpit & Sitzen", [
                  createRowWithDetailData("stem"),
                  createRowWithDetailData("barTape"),
                  createRowWithDetailData("saddle"),
                  createRowWithDetailData("seatPost"),
                  createRowWithDetailData("handleBar"),
                ])}

                {createRowGroup("Bremsen", [
                  createRowWithDetailData("brakesFront"),
                  createRowWithDetailData("brakesRear"),
                  createRowWithDetailData("brakeLever"),
                  createRowWithDetailData("brakesDiscDiameter"),
                ])}

                {createRowGroup("Schaltwerk", [
                  createRowWithDetailData("shiftType"),
                  createRowWithDetailData("gearShift"),
                  createRowWithDetailData("gearCount"),
                  createRowWithDetailData("shifters"),
                  createRowWithDetailData("rearDerailleurs"),
                  createRowWithDetailData("frontDerailleurs"),
                ])}

                {createRowGroup("Kraftübertragung", [
                  createRowWithDetailData("cassette"),
                  createRowWithDetailData("crankset"),
                  createRowWithDetailData("crankLenght"),
                  createRowWithDetailData("bottomBracket"),
                  createRowWithDetailData("chain"),
                  createRowWithDetailData("pedals"),
                  createRowWithDetailData("drivePosition"),
                ])}

                {createRowGroup("Laufräder & Reifen", [
                  createRowWithDetailData("tiresFront"),
                  createRowWithDetailData("tiresRear"),
                  createRowWithDetailData("wheelset"),
                  createRowWithDetailData("wheelSize"),
                  createRowWithDetailData("spokes"),
                  createRowWithDetailData("rims"),
                  createRowWithDetailData("hubFront"),
                  createRowWithDetailData("hubRear"),
                ])}

                {createRowGroup("Weitere Details", [
                  createRowWithDetailData("lightFront"),
                  createRowWithDetailData("lightRear"),
                  createRowWithDetailData("modelYear"),
                  createRowWithDetailData("mudGuard"),
                  createRowWithDetailData("parkingSupport"),
                ])}

                {!singleProduct &&
                  createRow(
                    "",
                    products.map((bike) => (
                      <ProductBestPrice key={bike.id} showPrice={true} product={bike} className={classes.price} />
                    ))
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SpecificationTable;
