import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import useTranslation from "next-translate/useTranslation";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  copyUrlButton: {
    fontWeight: 300,
  },
  copyUrlButtonActive: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  icon: {
    backgroundImage: "url(/images/share.svg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "block",
  },
}));

const CopyUrlButton = (props) => {
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");
  const [showConfirm, setShowConfirm] = useState(false);
  const url = typeof window != "undefined" && window ? window.location.href : "https://thecycleverse.com/";

  return (
    <CopyToClipboard
      text={url}
      onCopy={() => {
        setShowConfirm(true);
        setTimeout(() => {
          setShowConfirm(false);
        }, 2000);
      }}
    >
      <Button
        component="div"
        className={`${className ? className : ""} ${classes.copyUrlButton} ${
          showConfirm ? classes.copyUrlButtonActive : ""
        }`}
        endIcon={!showConfirm && <Icon className={classes.icon} />}
      >
        {showConfirm ? t("copyUrlButtonTextConfirm") : t("copyUrlButtonText")}
      </Button>
    </CopyToClipboard>
  );
};

export default CopyUrlButton;
